<template>
  <div class="jx-privilege">
    <div class="head-title">
      <p>鲸选特权</p>
    </div>
    <div class="head">
      <van-swipe  v-if="imgList.length > 0" :autoplay="3000" @change="onChangeImg">
        <template v-for="item in imgList">
          <van-swipe-item :key="item.id">
            <img :src="item.image" @click="handleJump(item.relationType, item)">
          </van-swipe-item>
        </template>
        <template #indicator>
          <div class="custom-indicator">{{ current + 1 }}/{{ totalCount }}</div>
        </template>
      </van-swipe>
    </div>
    <div class="privilege-body">
      <div class="list">
        <van-sidebar v-model="sideValue">
          <template v-for="item in navList">
            <van-sidebar-item :key="item.navCode" :title="item.navDesc" @click="onChange"/>
          </template>
        </van-sidebar>
      </div>
      <div class="category-list" id="scrollC">
        <template v-for="(item, index) in navList">
          <div :key="item.navCode" class="hot-recommend" :id="`block${index}`">
            <p>{{ item.navDesc }}</p>
            <div>
              <template v-for="item_c in item.positionInfoList">
                <div :key="item_c.id" class="recommend-wrap" @click="handleJump(item_c.relationType, item_c)">
                  <img :src="item_c.image"/>
                  <p class="title">{{ item_c.name }}</p>
                  <p class="count">{{ item_c.desc }}</p>
                </div>
              </template>
              <template v-for="(item_c) in liCount">
                <div class="list-control"></div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swipe, SwipeItem, Sidebar, SidebarItem } from 'vant'
import baseUrl from '@/utils/baseUrl'

const api = require('../../utils/api.js').api

Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
export default {
  name: 'jxPrivilege',
  data () {
    return {
      current: 0,
      totalCount: 0,
      imgList: [],
      //  左侧导航栏
      navList: [],
      positionInfoList: [],
      sideValue: '',
      //  滚动条滚动的距离
      decoration: 0,
      isScrollListen: true,
      row: 3
    }
  },
  computed: {
    liCount: function () {
      return this.row - (this.totalCount % this.row);
    }
  },
  created () {
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    document.getElementById('scrollC').addEventListener('scroll', this.scrollChangeTab)
    // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
    this.$once('hook:beforeDestroy', () => {
      document.getElementById('scrollC').removeEventListener('scroll', this.scrollChangeTab)
    })
    this.handleGetList()
    this.handleGetIcon()
    this.showTitleBar(false)
    this.updateStyle('white', 'black')
  },
  methods: {
    /*
    显示获隐藏导航标题栏
    */
    showTitleBar (showTitleBar) {
      this.$h5AppLink('showTitleBar', {
        showTitleBar: showTitleBar
      })
    },
    /*
    设置状态栏颜色
    */
    updateStyle (bgColor, textColor) {
      //  在鲸选师APP内，设置APP的状态栏
      if (this.$store.state.isAndroidApp) {
        this.$h5AppLink('updateStyle', {
          statusBarBgColor: bgColor,
          statusBarTextColor: textColor
        })
      }
    },
    /*
    轮播change事件
     */
    onChangeImg (index) {
      this.current = index
    },
    /*
    推广位列表查询banner
     */
    handleGetList () {
      this.$axios(api.categoryList, {
        params: {
          categoryId: 26,
          clientType: 2
        }
      }).then(res => {
        this.imgList = res.data.data
        this.totalCount = res.data.data.length
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },
    /*
    带二级分类的推广位查询
     */
    handleGetIcon () {
      this.$axios(api.categoryIcon, {
        params: {
          categoryId: 25,
          clientType: 2
        }
      }).then(res => {
        this.navList = res.data.data
        this.positionInfoList = this.navList[0].positionInfoList
      }).catch(err => {
        console.info(err)
      })
    },
    /*
    跳转banner
     */
    handleJump (type, item) {
      //  1-关联商品;2-关联活动;3-常规H5链接
      //  4-关联0元购;5-关联文章;6-关联内链;7-关联超级补贴;
      //  8-关联生活服务;9-视频引导;10-广告视频;11-全屏H5链接;
      switch (type) {
        case 1:
          this.$h5AppLink('openGoodsInfo', {
            itemSource: item.relationSource,
            itemId: item.relationId
          })
          break
        case 2:
          this.$router.push({
            name: 'activity',
            query: {
              id: item.activityInfo.id
            }
          })
          this.showTitleBar(true)
          break
        case 3:
        case 6:
        case 7:
        case 11:
          this.$h5AppLink('openWebview', {
            url: item.relationUrl || item.relationAnUrl
          })
          break
        case 4:
          this.$h5AppLink('openFreeActivity', {
            id: item.relationId
          })
          break
        case 5:
          this.$router.push({
            name: 'article',
            query: {
              id: item.relationId
            }
          })
          break
        case 8:
          this.$axios.post(api.transfer, {
            type: item.relationId,
            clientType: 2
          }).then(res => {
            this.$h5AppLink('openWebview', {
              url: res.data.data.spreadUrl,
              joinDefaultParams: false
            })
          }).catch(err => {
            this.isLogin()
            console.info(err)
          })
          this.showTitleBar(true)
          break
        case 9:
          this.$h5AppLink('openWebview', {
            url: item.relationUrl || item.relationAnUrl
          })
          break
        case 10:
          break
      }
    },
    /*
    侧边导航栏
     */
    onChange (index) {
      this.isScrollListen = false
      const el = 'block' + index
      //  滚动进入可视范围
      document.getElementById(el).scrollIntoView({
        behavior: 'smooth'
      })
      setTimeout(() => {
        this.isScrollListen = true
      }, 1500)
    },
    /*
    滚动切换tab
    */
    scrollChangeTab () {
      if (!this.isScrollListen) {
        return
      }
      let scrollTop = document.getElementById('scrollC').scrollTop
      let scroll = scrollTop - this.decoration
      this.decoration = scrollTop
      this.navList ? this.navList.map((item, index) => {
        const el = 'block' + index
        let offsetTop = document.getElementById(el).offsetTop
        if (scrollTop + 200 >= offsetTop) {
          this.sideValue = index
        }
      }) : []
    },
    /*
    登录验证
     */
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/purchase`
      }
      this.$h5AppLink(event, param)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .list {
  .van-sidebar {
    width: 100px;
    text-align: center;
    .van-sidebar-item{
      background: #FFFFFF;
      color: #666666;
      font-size: 15px;
    }

    .van-sidebar-item--select {
      color: #333333;
      font-weight: bold;
    }
    .van-sidebar-item--select::before{
      width: 6px;
      height: 18px;
      background: #FFEA1C;
      border-radius: 4px;
    }
  }
}

.jx-privilege {
  min-height: 100vh;
  background: #F9F9F9;

  .head-title {
    width: 375px;
    height: 45px;
    background: #FFFFFF;

    & > p {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 45px;
    }
  }

  .head {
    width: 360px;
    display: inline-block;
    margin: 10px 7.5px;

    .van-swipe {
      width: 360px;
      height: 124px;

      .van-swipe-item {
        line-height: 0;

        & > img {
          width: 360px;
          height: 124px;
          border-radius: 8px;
        }
      }
    }

    .custom-indicator {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 2px 5px;
      font-size: 12px;
      color: #FFFFFF;
      background: #333333;
      opacity: 0.8;
    }
  }

  .privilege-body {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .list {
      padding: 10px 0;
      width: 100px;
      height: 600px;
      background: #FFFFFF;
      border-radius: 10px;
    }
    .category-list{
      padding-bottom: 10px;
      width: 265px;
      height: 610px;
      overflow-y: auto;
      .hot-recommend{
        margin-bottom: 10px;
        width: 245px;
        padding: 15px 10px 20px;
        background: #FFFFFF;
        border-radius: 10px;
        & > p{
          margin-bottom: 10px;
          text-align: center;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
        & > div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .recommend-wrap{
            margin-bottom: 10px;
            width: 80px;
            text-align: center;
            & > img{
              width: 50px;
              height: 50px;
            }
            .title{
              margin-top: 4px;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
            }
            .count{
              font-size: 12px;
              color: #999999;
              line-height: 17px;
            }
          }
        }
        .list-control {
          content: "";
          width: 80px;
          height: 20px;
          border: 1px solid transparent;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
